<template>
  <div
    class="container"
    v-title
    id="mainSchedule"
    :data-title="$t('i18n.mainSchedule')"
  >
    <div id="outer-title">{{ $t("i18n.mainSchedule") }}</div>
    <el-input
      class="search-input"
      v-model="projectName"
      @change="init"
      :placeholder="$t('i18n.projectName')"
    ></el-input>
    <el-select
      filterable
      clearable
      v-model="provinceIndex"
      @change="changeCityData"
      :placeholder="$t('i18n.province')"
    >
      <el-option
        v-for="(item, index) in provinceData"
        :key="index"
        :label="item.label"
        :value="index"
      ></el-option>
    </el-select>
    <el-select
      filterable
      clearable
      class="city"
      v-model="city"
      @change="init"
      :placeholder="$t('i18n.city')"
    >
      <el-option
        v-for="(item, index) in cityData"
        :key="index"
        :label="item.label"
        :value="item.label"
      ></el-option>
    </el-select>
    <div class="chartContain" v-loading="loading">
      <jl-chart v-if="!loading" name="mulOption" :columns="option"></jl-chart>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import jlChart from "../../components/chart";
var allData = {};
export default {
  name: "BillChartStatic",
  components: {
    jlChart,
  },
  setup: (props, { emit }) => {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const state = reactive({
      projectName: "",
      province: "",
      city: "",
      provinceIndex: "",
      provinceData: proxy.$defined.getProvinceData(),
      cityData: [],
      loading: true,
      option: {
        timeline: [],
        type: "pie",
        series: [],
        mulData: [],
      },
    });

    const init = () => {
      let nowYear = new Date().getFullYear();
      state.option.timeline = [];
      for (let i = 2016; i <= nowYear; i++) {
        state.option.timeline.push(i);
        initData(i);
      }
      setOption();
    };

    const setOption = () => {
      state.option.series.push({
        name: t("i18n.maintenance"),
        type: "pie",
        radius: ["35%", "55%"],
        z: 100,
      });
    };

    const initData = async (year) => {
      let { data } = await proxy.$api.maintenance.getBillChartStatic({
        year: year,
        projectName: state.projectName,
        province: state.province,
        city: state.city,
        tabType: "containerMonthDiv",
      });
      allData[year] = data;
      setDataByYear();
    };

    const setDataByYear = () => {
      state.option.mulData = [];
      for (let key in allData) {
        state.option.mulData.push({
          title: { text: key + t("i18n.mainSchedule") },
          series: { data: allData[key] },
        });
      }
      state.loading = false;
    };

    const changeCityData = (index) => {
      state.city = "";
      state.cityData = state.provinceData[index].children;
      state.province = state.provinceData[index].label;
      init();
    };

    init();

    return {
      ...toRefs(state),
      changeCityData,
      init,
    };
  },
};
</script>

<style lang="scss">
#mainSchedule {
  .chartContain {
    height: 700px;
  }
  .search-input {
    position: absolute;
    z-index: 2;
    top: 45px;
    left: 45px;
    width: 300px;
  }
  .el-select {
    position: absolute;
    z-index: 2;
    top: 95px;
    left: 45px;
    width: 145px;
  }
  .city {
    left: 200px;
  }
}
@media screen and (max-width: 768px) {
  #mainSchedule {
    .chartContain {
      height: 500px;
    }
    .search-input {
      position: relative;
      width: 300px;
      top: 0;
      left: 10px;
    }
    .el-select {
      position: relative;
      top: 0;
      left: 0;
      width: 145px;
      margin: 10px 0 10px 10px;
    }
  }
}
</style>
